.dropzone {
  text-align: center;
  padding: 20px;
  border: 2px #FFC70B dashed;
  width: 60%;
  min-width: 250px;
  max-width: 350px;
  margin: 15px auto;
}

.dropzone-content {
  font-size: 0.8rem;
}

.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}

.btn:hover {
  background-color: blue;
  color: aliceblue;
}

.grid-photos {
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  /* padding: 0 4px; */

}

.file-list {
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
}

.file-list img {
  max-height: 100px;
  width: 100px;
  padding: 0 0 5px 5px;
  object-fit: cover;
  border-radius: 15px;
}

.filevideo {
  height: 150px;
  padding: 0 0 5px 5px;
  object-fit: cover;
}
