.section____center1 {
  width: 1200px;
  margin: 0 auto;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 450px;
  border: 5px solid #fff;
}

.loyalty-container {
  width: 1200px;
  margin: 0 auto;
  /* border: 1px dotted #0c6157; */
}

.card-design {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* /* border: 1px dotted #0c6157; */
}

.card-template {
  border: 1px dashed #0c6157;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.card-template h4 {
  text-shadow: 3px 3px 3px #e3d000;
  margin: 10px 10px 20px 20px;
  font-size: 24px;
  color: #0c6157;
}

.card-outside {
  border: 1px solid gray;
  max-width: 350px;
  /* height: 1000px; */
  border-radius: 10px;
  margin: 0 auto;
}
.card-border {
  border: 1px solid #0c6157;
  /* box-shadow: 3px 3px 3px #e3d000; */
  /* background-color: #0c6157; */
  border-radius: 10px;
  width: 350px;
  height: 550px;
}

.upper-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  /* border: 1px dotted white; */
}

.stamps-required {
  margin-right: 20px;
}

.stamps-required p {
  color: white;
}

.stamps-required h3 {
  color: white;
  float: right;
}

.upper-section img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin: 15px;
}

.upper-section h5 {
  color: white;
  font-size: 16px;
  margin-left: 10px;
}

.stamped-section {
  /* background-color: #e3d000; */
  /* background: linear-gradient(
      #000000,
      rgba(255, 255, 255, 0.2)
    ); */


  background-size: cover;
  height: 200px;
  display: flex;
  flex-direction: column;
}

.bottom-section {
  /* border: 1px solid red; */
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.bottom-section p {
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.bottom-section img {
  margin-top: 0px;
  height: 120px;
}

/* Card information section */
.card-info {
  width: 600px;
  height: 1000px;
  /* border: 1px dashed #0c6157; */
  border-radius: 20px;
  margin-top: 30px;
}

.card-info h3 {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 10px;
  background-color: #e3d000;

  border-bottom: 1px solid #0c6157;
  box-shadow: 0 0 3px #0c6157;
}

.card-info h3:hover {
  background-color: #0c6157;
  color: white;
}

.background-button {
  display: flex;
  width: 500px;
  height: 100px;
  border-bottom: 1px dotted #0c6157;
  justify-content: space-between;
  margin: auto;
  padding: 10px;
}

.value {
  border-left: 12px solid #000;
  padding-left: 10px;
  margin-top: 00px;
}

/* Detail section */

.detail-section {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 20px;
  /* border: 1px solid #000; */
  width: 80%;
}

.decription-section {
  padding: 10px;
  border-bottom: 1px dashed lightgrey;
  max-width: 350px;
  margin: 0 auto;
}

.decription-section p {
  font-size: 12px;
}

.decription-section-h4 {

  font-size: 1.5rem;
  font-weight: 700;
}

/* section data */
.section__data {
  display: grid;
  justify-items: center;
  border: 1px dashed #0c6157;
  background: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.2)
    );
    /* url(../../../assets/logo/pexels-photo-7429579.jpeg); */
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
}

.section__data span {
  font-size: 20px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
}

.filter-btn1 {
  /* background: #0c6157; */
  /* border-color: lightgray; */
  font-size: 20px;
  text-transform: capitalize;
  margin: 10 0rem;
  letter-spacing: 0px;
  padding: 0rem 0.5rem;
  /* color: white; */
  cursor: pointer;
  font-weight: 500;
  transition: var(--transition);
  border-radius: 20px;
  width: 100px;
}
.filter-btn1:hover {
  background: #e3d000;
  color: var(--clr-white);
}

.data__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px dotted blue; */
  width: 300px;
}

@media screen and (min-width: 200px) {
  .card-design {
    width: 390px;
    display: grid;
    grid-template-columns: 1fr;
    /* border: 1px dotted red; */
  }
}

@media screen and (min-width: 1000px) {
  .card-design {
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
