@import url(https://fonts.googleapis.com/css2?family=Bruno+Ace&family=Lobster&display=swap);
@charset "UTF-8";
/* ==== CUSTOM UI ELEMENTS AND STYLES ====*/
/* ========================
INCLUDE STYLES AND COMPONENTS
===================== */
/* ================== HELPERS =================== */
.table thead th {
  color: #adb5bd;
  font-weight: 400;
}
.table-lg td,
.table-lg th {
  padding: 0.8rem 1rem;
}
tbody td {
  border-bottom: 0;
}
tbody tr {
  border-bottom: 1px solid #cfdbe6;
}
tbody tr:hover {
  background-color: #ebf0fd !important;
}
a.disabled {
  pointer-events: none;
}
.btn-primary {
  /* background-color: #4fa607 !important;
  border: 1px solid #4fa607 !important; */
  background-color: #f44336 !important;
  border: 1px solid #f44336 !important;
}
.Header-green {
  /* background-color: #4fa607; */
  background-color: #f44336;
}
/* .screen-overlay {
  width: 0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;
}

.screen-overlay.show {
  transition: opacity 0.5s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
} */

/* =================  DEFINED VARIABLES =================== */
.icon-xs,
.icon-sm,
.icon-md,
.icon-lg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.icon-xs {
  width: 32px;
  height: 32px;
  line-height: 32px !important;
  font-size: 16px;
}

.icon-sm {
  width: 48px;
  height: 48px;
  line-height: 48px !important;
  font-size: 20px;
}

.icon-md {
  width: 60px;
  height: 60px;
  line-height: 60px !important;
  font-size: 28px;
}

.icon-lg {
  width: 96px;
  height: 96px;
  line-height: 96px !important;
  font-size: 42px;
}

.img-avatar {
  border-radius: 100%;
  border: 3px solid #fff;
}

.img-xs,
.img-sm,
.img-md,
.img-lg {
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 0;
}

.img-xs {
  width: 40px;
  height: 40px;
}

.img-sm {
  width: 60px;
  height: 60px;
}

.img-md {
  width: 112px;
  height: 112px;
}

.img-lg {
  width: 196px;
  height: 196px;
}

.center-xy {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.center-y {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.center-x {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

/* ================== BASE =================== */
body {
  position: relative;
  min-height: 100vh;
}

body.offcanvas-active {
  overflow: hidden;
}

.main-wrap {
  margin-left: 260px;
  /* background-color: #f8f9fa; */
}

.content-main {
  padding: 30px 3%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-aside {
  max-width: 260px;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 10;
  border-right: 1px solid rgba(108, 117, 125, 0.25);
}
.navbar-aside .aside-top {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-aside .aside-top .brand-wrap {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
}
.navbar-aside .aside-top .logo {
  height: 46px;
  width: auto;
  max-width: 200px;
}

.main-header {
  padding-left: 3%;
  padding-right: 3%;
  min-height: 72px;
  background-color: #fff;
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
  color: white;

}
.main-header .nav {
  align-items: center;
}
.main-header .nav-item > a {
  border-radius: 0.25rem;
  color: white;
  display: block;
  text-decoration: none;
}
.main-header .nav-link:hover {
  /* background-color: #e9ecef; */
  color: #FFC70B;
  /* color: #fff; */
}
.main-header .col-search {
  flex-grow: 0.5;
}
.main-header .col-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-item{
  padding: 0 5px;
}

.nav-item img.rounded-circle {
  border: 2px solid #eee;
}
.nav-item img.rounded-circle:hover {
  /* border-color: #4fa607; */
  border-color: #f44336;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.content-header .content-title {
  margin-bottom: 0;
}

.inner {
  padding: 1rem;
}

body.aside-mini .main-wrap {
  margin-left: 70px;
}
body.aside-mini .navbar-aside {
  max-width: 70px;
  overflow: visible;
  position: absolute;
  min-height: 100%;
  bottom: auto;
}
body.aside-mini .navbar-aside .aside-top {
  text-align: center;
}
body.aside-mini .navbar-aside .aside-top > div {
  flex-grow: 1;
}
body.aside-mini .navbar-aside .brand-wrap,
body.aside-mini .navbar-aside .logo {
  display: none;
}
body.aside-mini .menu-aside .menu-link {
  text-align: center;
}
body.aside-mini .menu-aside .menu-link .text {
  display: none;
}
body.aside-mini .menu-aside .menu-link .icon {
  margin-right: 0;
}
body.aside-mini .menu-aside .submenu {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  margin-left: 0;
  padding: 10px;
  position: absolute;
  left: 100%;
  top: 0;
  width: 180px;
}
body.aside-mini .menu-aside .menu-item {
  position: relative;
}
body.aside-mini .menu-aside .menu-item:hover .submenu {
  display: block;
  z-index: 10;
}
body.aside-mini .menu-aside .menu-item.has-submenu .menu-link:after {
  display: none;
}

.menu-aside {
  list-style: none;
  margin: 0.5rem;
  padding: 0;
}
.menu-aside a {
  display: block;
  text-decoration: none;
}
.menu-aside .menu-item {
  margin-bottom: 5px;
}
.menu-aside .menu-item .icon {
  color: #adb5bd;
  margin-right: 10px;
  font-size: 18px;
}
.menu-aside .active .icon {
  /* color: #4fa607; */
  color: #f44336; /*red*/

}
.menu-aside .active {
  background-color: #ebf0fd;
}
.menu-aside .menu-link {
  padding: 10px;
  color: #29335d;
  font-weight: bold;
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-aside .menu-link .text {
  vertical-align: middle;
}
.menu-aside .menu-link:hover {
  transition: 0.2s linear;
  background-color: #e9ecef;
}
.menu-aside .submenu {
  margin-left: 44px;
  display: none;
}
.menu-aside .submenu a {
  color: #6c757d;
  padding: 5px;
}
.menu-aside .submenu a:hover {
  color: #000;
}
.menu-aside .menu-item.active .submenu {
  display: block;
}
.menu-aside .menu-item.has-submenu > .menu-link:after {
  display: inline-block;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-top: 0.6em;
  vertical-align: middle;
  content: "";
  border-top: 5px solid #adb5bd;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.nav-pills .nav-link {
  color: #6c757d;
  font-weight: 500;
}
.nav-pills .nav-link:hover {
  color: #141432;
  background-color: rgba(173, 181, 189, 0.15);
}

/* --------------------- titles ---------------------- */
.section-heading {
  margin-bottom: 40px;
  margin-top: 0;
}
.section-heading p {
  max-width: 600px;
  margin: auto;
}

.section-title {
  font-weight: 500;
}

.title-text {
  margin-top: 45px;
  margin-bottom: 20px;
}

.b {
  font-weight: 600;
}

/* ==================  title helpers ================ */
.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

/* ================== COMPONENTS =================== */
a[class*="card"] {
  color: #000;
  color: initial;
}
a[class*="card"]:hover .title {
  color: #f44336;
}

/* ITEM LIST */
.itemlist {
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.5rem;
}
.itemlist:hover {
  background-color: rgba(49, 103, 235, 0.075);
}

/* ITEMSIDE */
.itemside {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}
.itemside .aside,
.itemside .left {
  position: relative;
  flex-shrink: 0;
}
.itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}
.itemside p {
  margin-bottom: 0;
}
.itemside .title {
  display: block;
  margin-bottom: 5px;
}
.itemside a.title:hover {
  /* color: #4fa607; */
  color: #f44336;
}

a.itemside {
  color: #000;
  color: initial;
  text-decoration: none;
}
a.itemside:hover .title {
  text-decoration: underline;
}

/* BASE STYLE FOR PRODUCT ITEM */
[class*="card-product"] {
  border: 1px solid rgba(108, 117, 125, 0.25);
}
[class*="card-product"] p {
  margin-bottom: 0;
}
[class*="card-product"] .img-wrap {
  text-align: center;
  display: block;
  overflow: hidden;
  position: relative;
  background: white;
}
[class*="card-product"] .img-wrap img {
  height: 100%;
  max-width: 100%;
  width: auto;
  display: inline-block;
  object-fit: cover;
}

/* GRID STYLE PRODUCT ITEM */
.card-product-grid {
  margin-bottom: 20px;
}
.card-product-grid .img-wrap {
  border-radius: 0.2rem 0.2rem 0 0;
  height: 220px;
}
.card-product-grid .info-wrap {
  padding: 1rem;
}
.card-product-grid a.title {
  color: #6c757d;
  display: block;
  text-decoration: none;
}
.card-product-grid a.title:hover {
  /* color: #4fa607; */
  color: #f44336;

}
.card-product-grid:hover {
  border-color: #adb5bd;
}

/* LARGE LIST STYLE PRODUCT ITEM */
.card-product-list {
  margin-bottom: 20px;
}
.card-product-list .img-wrap {
  height: 220px;
  border-radius: 0.25rem 0 0 0.25rem;
}
.card-product-list .info-main {
  padding: 1.5rem 1rem;
}
.card-product-list .info-aside {
  padding: 1.5rem 1rem;
  border-left: 1px solid rgba(108, 117, 125, 0.25);
  height: 100%;
}

.card-user {
  margin-bottom: 20px;
}
.card-user .card-header {
  position: relative;
  height: 100px;
  background-color: #c3f19d !important;
  text-align: center;
}
.card-user .img-avatar {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 100%;
}
.card-user .card-body {
  text-align: center;
}

/* --------- description list --------*/
[class*="dlist"] {
  margin-bottom: 5px;
}

.dlist-inline dt,
.dlist-inline dd {
  display: inline-block;
}

.dlist {
  display: flex;
}
.dlist dt {
  width: 150px;
  font-weight: normal;
}
.dlist dd {
  margin-left: 30px;
  vertical-align: baseline;
  flex-grow: 1;
  margin-bottom: 0;
  text-align: right;
}

/* -------------------- list.row--------------------- */
ul.row,
ul.row-sm {
  list-style: none;
  padding: 0;
}

/* ================= lists ================= */
[class*="list-"] li:after {
  visibility: hidden;
  display: block;
  content: "";
  overflow: hidden;
  height: 0;
  clear: both;
}

.list-icon {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.list-icon li {
  margin-bottom: 7px;
  position: relative;
  padding-left: 30px;
}
.list-icon .icon {
  width: 22px;
  margin-right: 15px;
  vertical-align: middle;
  text-align: center;
  color: #6c757d;
  position: absolute;
  top: 3px;
  left: 0;
}
.list-icon span {
  vertical-align: middle;
}

.list-bullet {
  list-style: none;
  padding-left: 0;
}

.list-bullet li {
  margin-bottom: 5px;
  position: relative;
  padding-left: 15px;
}

.list-bullet li::before {
  content: " ";
  position: absolute;
  top: 8px;
  left: 0;
  width: 7px;
  border-radius: 100%;
  height: 7px;
  background: #4fa607;
}

.list-check {
  padding-left: 0;
  list-style: none;
}
.list-check > li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 7px;
}
.list-check > li::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  color: #f44336;
  position: absolute;
  top: 2px;
  left: 0;
  content: "";
}

.list-normal {
  list-style: none;
  padding-left: 0;
}
.list-normal li {
  padding-left: 1em;
  margin-bottom: 7px;
}
.list-normal li:before {
  content: "•";
  color: #4fa607;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.list-menu {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.list-menu li {
  margin-bottom: 5px;
}
.list-menu a {
  color: #141432;
}
.list-menu a:hover {
  /* color: #4fa607; */
  color: #f44336; /*red*/

}

.cols-two {
  column-count: 2;
}

.cols-three {
  column-count: 3;
}

.cols-four {
  column-count: 4;
}

.icontext {
  display: inline-flex;
  align-items: center;
  padding: 5px;
}
.icontext .icon {
  position: relative;
  flex-shrink: 0;
  margin-right: 10px;
}
.icontext small,
.icontext .title {
  display: block;
}

.tag {
  display: inline-block;
  padding: 3px 7px;
  background: #f2f2f2;
  border: 1px xolid #eee;
  border-radius: 3px;
  margin-top: 4px;
  margin-right: 2px;
  font-size: 85%;
}

.price {
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.notify {
  position: absolute;
  top: -4px;
  right: -10px;
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  border-radius: 3rem;
  color: #fff;
  background-color: #f44336;
}

.map-pin {
  border-radius: 3rem;
  background-color: #f44336;
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
}
.map-pin:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-block;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.3);
}

.icon-action {
  margin-top: 5px;
  float: right;
}

/* ====================== box ==================== */
.box {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(108, 117, 125, 0.2);
  background: #fff;
}
.box img {
  max-width: 100%;
}

/* ================= RATINGS ============== */
.label-rating {
  margin-left: 7px;
  display: inline-block;
  vertical-align: middle;
}

/* rating-stars */
.rating-stars {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  clear: both;
  line-height: 0.8;
}
.rating-stars i {
  font-size: 14px;
  color: #ccc;
  display: inline;
}
.rating-stars img {
  height: 16px;
  max-width: none;
}
.rating-stars li {
  display: block;
  text-overflow: clip;
  white-space: nowrap;
  z-index: 1;
}
.rating-stars li.stars-active {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.rating-stars li.stars-active i {
  color: orange;
}
.rating-stars.stars-lg img {
  height: 24px;
  max-width: none;
}

.form-inline input[type="number"] {
  max-width: 100px;
}

.form-control{
  background: #fff;
}

.form{
  background: transparent;

}

.form-control:hover,
.form-select:hover {
  border-color: rgba(255,199,11,1);
  background-color: rgba(255,199,11,0.2);
}

.btn-facebook {
  background-color: #405d9d;
  color: #fff;
}
.btn-facebook:hover {
  color: #fff;
}

.btn-instagram {
  background-color: #e52d27;
  color: #fff;
}
.btn-instagram:hover {
  color: #fff;
}

.btn-youtube {
  background-color: #c8046c;
  color: #fff;
}
.btn-youtube:hover {
  color: #fff;
}

.btn-twitter {
  background-color: #42aeec;
  color: #fff;
}
.btn-twitter:hover {
  color: #fff;
}

.btn-google {
  background-color: #ff7575;
  color: #fff;
}
.btn-google:hover {
  color: #fff;
}

.btn-icon {
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.btn-icon:hover {
  background-color: rgba(108, 117, 125, 0.15);
}
.btn-icon:focus {
  box-shadow: none;
}

.btn-light {
  background-color: #fff;
  border-color: rgba(108, 117, 125, 0.25);
}
.btn-light i {
  color: #adb5bd;
}
.btn-light:hover {
  background-color: #fff;
  border-color: #adb5bd;
}

.btn-drag {
  padding: 5px 2px;
  display: inline-block;
  cursor: ns-resize !important;
}

.btn-sm i {
  font-size: 1.2rem;
  line-height: 0.7;
  vertical-align: bottom;
}


/* App css Client Shoe Shop */
body {
  margin: 0;
  /* font-family: "Josefin Sans", sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: black;
}
i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}
.row,
.col-6,
.col-4 {
  margin: 0;
  padding: 0;
}
.Announcement {
  background-color: #1cb803;
  padding: 10px 0px 6px 0px;
}
.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}
.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}
/* Header */
.header {
  padding: 10px 0px 10px 0px;
}
.navbar-brand img {
  width: 60%;
}
.input-group {
  height: 50px;
  width: 90%;
  display: flex;
  align-items: center;
}
.search-button {
  width: 20%;
  background-color: black;
  color: white;
  border: 0;
  text-transform: uppercase;
  font-size: 12px;
}
.Login-Register a {
  text-transform: uppercase;
  font-size: 15px;
  margin-right: 2rem;
  position: relative;
}
.Login-Register a:nth-last-child(1) {
  margin-right: 0rem;
}
.badge1 {
  background-color: green;
  color: white;
  position: absolute;
  top: 0px;
  right: 60px;
  border-radius: 100px;
  padding: 3px 10px;
  font-size: 12px;
}
.name-button {
  padding: 5px 15px;
  border: 1px solid #e4e4e4;
  background-color: white;
  border-radius: 2px;
  margin: 0 15px;
}

.dropdown-menu {
  background-color: black !important;
  border: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}
.dropdown-menu a {
  padding: 10px 20px !important;
  color: #ffffff !important;
  margin: 0 !important;
}
.dropdown-menu a:hover {
  background-color: #1cb803 !important;
  color: #ffffff !important;
}
.mobile-header {
  display: none;
}

/* SHOP */
.section {
  width: 100%;
  padding-bottom: 6%;
  padding-top: 2%;
}
.sidebar_item {
  margin-bottom: 35px;
}
.sidebar_item h4 {
  color: #1c1c1c;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}
.sidebar_item ul {
  margin: 0;
  padding: 0;
}
.sidebar_item ul li {
  list-style: none;
  display: flex;
}

.sidebar_item ul li a {
  font-size: 16px;
  color: #000000;
  line-height: 45px;
  display: block;
  font-family: "Open Sans", sans-serif;
}
.sidebar_item ul li img {
  width: 25px;
  object-fit: contain;
  margin-left: 20px;
}
.shop {
  margin-bottom: 50px;
}
.border-product {
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
}
.shopBack {
  height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #f3fbf7;
}
.shopBack img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}
.shoptext {
  padding-top: 10px;
}
.shoptext p a {
  color: #252525;
}
.shoptext h3 {
  color: #252525;
  margin-top: 13px;
  font-weight: bold;
  font-size: 19px;
}
.rating {
  margin: 5px 0;
}
.rating i {
  font-size: 12px;
  color: #ebb450;
}
.rating span {
  margin-left: 10px;
}
/* END OF SHOP */

/* NEWSLATTER */
.subscribe-section {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.226)),
    url("https://sasshoes-com.s3.us-west-2.amazonaws.com/img/banner-uplift-21.jpg")
      no-repeat scroll center / cover;
  padding: 100px 0 100px;
}
.subscribe-head h2 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 13px;
  text-transform: uppercase;
}
.subscribe-head p {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 39px;
}
.subscribe-head {
  text-align: center;
}
.form-section input[type="email"] {
  border: 0 none;
  border-radius: 25px;
  color: #9b9b9b;
  font-size: 16px;
  font-style: normal;
  height: 51px;
  letter-spacing: 0;
  text-align: center;
  width: 460px;
}
.form-section input[type="submit"] {
  background: #1cb803;
  border: 0 none;
  border-radius: 25px;
  color: #fff;
  height: 52px;
  letter-spacing: 0;
  margin-left: -60px;
  padding: 0 58px;
  text-transform: uppercase;
  font-size: 12px;
}
/* CONTACT */
.contactInfo {
  padding: 70px 0;
}
.contact-Box,
.box-info,
.info-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-info {
  width: 80%;
  flex-direction: column;
  padding: 40px 0;
  box-shadow: 0px 0px 27px 0px rgb(243, 243, 243);
}
.info-image {
  width: 50px;
  height: 50px;
  border: 2px solid #1cb803;
  border-radius: 100px;
  font-size: 20px;
  color: #1cb803;
}
.box-info h5 {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
  margin-bottom: 4px;
}
.box-info p {
  font-size: 16px;
}

/* SINGLE PRODUCT */
.single-product {
  margin-top: 50px;
  margin-bottom: 50px;
}
.single-image {
  background-color: #f3fbf7;
  margin-right: 20px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single-image img {
  width: 90%;
  height: 700px;
  object-fit: contain;
}
.product-name {
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 30px;
}

.display-flex {
  display: flex;
}
.product-info {
  width: 100%;
}
.product-dtl p {
  line-height: 24px;
  color: #7a7a7a;
}
.product-count {
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  margin-top: 40px;
}
.product-count .flex-box {
  padding: 15px 25px;
  border-bottom: 2px solid #f3f3f3;
}
.product-count .flex-box h6 {
  font-size: 16px;
}
.product-count .flex-box span {
  font-weight: 600;
  color: #000000;
}
.product-count .flex-box select {
  background-color: #f3f3f3;
  width: 100px;
  height: 40px;
  text-align: center;
  border: 0px;
  border-radius: 5px;
}
.round-black-btn {
  border-radius: 4px;
  background: #000000;
  color: #fff;
  width: 100%;
  height: 50px;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.5s ease-in-out 0s;
}
.round-black-btn:hover,
.round-black-btn:focus {
  background: #1cb803;
}

/* LOGIN */
.login-center {
  padding: 50px 0;
}
.Login,
.Login2 {
  background: #fff;
  border-radius: 5px;
  position: relative;
  padding: 35px 30px 30px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
  min-width: 800px;
}

.form-login3{
  background: rgba(255, 255,255, 0.3);
  border-radius: 25px;
  position: relative;
  padding: 35px 30px 30px;
  box-shadow: 0 1px 11px rgba(168, 168, 168, 0.27);
  text-align: center;
  min-width: 340px;
  margin-bottom: 1rem;
  /* backdrop-filter: blur(20px) */
}
.Login input {
  width: 100%;
  padding: 20px 20px;
  margin-top: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  color: #8a8a8a;
}
.Login input:focus {
  outline: 0px;
}
.Login button,
.Login2 button {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  border: 0;
  background-color: #1cb803;
  border-radius: 5px;
  text-transform: uppercase;
  color: white;
}
.Login button a,
.Login2 button a {
  text-transform: uppercase;
  color: white;
}
.Login p {
  margin-top: 30px;
}
.Login p a {
  color: #7a7a7a;
}

.box-area{
  width: 1280px;
}

/*------------ Right box ------------*/

.right-box{
  padding: 40px 30px 40px 40px;
  /* border: 1px solid red; */
}

.left-box-signin{
  padding: 40px 30px 40px 40px;
  /* border: 1px solid red; */
}


/*------------ Custom Placeholder ------------*/

:-ms-input-placeholder{
  font-size: 16px;
}

::placeholder{
  font-size: 16px;
}

.rounded-4{
  border-radius: 20px;
}
.rounded-5{
  border-radius: 30px;
}


/*------------ For small screens------------*/

@media only screen and (max-width: 768px){

   .box-area{
      margin: 20px;

   }
   .left-box{
      height: 100px;
      overflow: hidden;
   }

   .left-box-signin{

    margin-top: 20px;
    margin-bottom: 20px;

    padding: 0px;
 }
   .right-box{
      margin-top: 20px;
      padding: 0px;
   }
   .right-box-signin{
    height: 100px;
    overflow: hidden;
 }

}



/* PROFILE */
.author-card {
  position: relative;
}
.author-card-cover {
  height: 300px;
  width: 100%;
  background-size: cover;
  /* background-image: url("https://thumbs.dreamstime.com/b/golden-stars-glitter-yellow-paper-background-festive-holiday-bright-backdrop-155694234.jpg"); */
}
.author-card-profile {
  padding: 20px 10px;
}
.author-card-avatar {
  padding: 0 20px;
}
.author-card-avatar img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 100px;
  margin-top: -20%;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.377);
}
.wizard .nav button {
  padding: 15px 20px;
  font-size: 13px;
  background-color: #ffffff;
  color: black;
  width: 100%;
  text-align: start;
  border-radius: 0;
  text-transform: uppercase;
  border-top: 1px solid #e4e4e4;
}
.wizard .nav .active {
  /* background-color: #e5fee9; */
  background-color: #FFC70B;

  color: black;
}

.badge2 {
  width: 20px;
  height: 20px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #ffffff;
}
.form {
  padding: 20px 0;
}
.form label {
  padding: 10px 0;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 13px;
}
.form input {
  padding: 20px 10px;
  font-size: 17px;
  /* background-color: #e5fee9; */
  background-color: #FFC70B; 
}
.form-container button {
  padding: 15px 0;
  border: 0px;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
}
/* orders */
.alert, .alert-info {
  color: #000;
  background-color: rgba(255,199,11,0.2);
  border-color: rgba(255,199,11,0.2);
}

.btn-success{
  background-color: rgba(255,199,11,1);
  border-color: rgba(255,199,11,1);
  color: #000;
  font-weight: 700;
}

.link {
  color: blue;
  border-bottom: 1px solid blue;
  padding-bottom: 5px;
  font-size: 15px;
}
.link:hover {
  color: blue;
}
.table-responsive {
  width: 100%;
}
/* CART */
.container1{
  min-width: 1200px;
  padding: 100px;
}
@media (max-width: 768px) {
  .container1{
    min-width: 300px;
    padding: 10px;
  }
}
.cart-iterm {
  margin: 40px 0;
  padding: 20px;
  background-color: white;
  box-shadow: 0 12px 20px 1px rgba(64, 64, 64, 0.09);
  position: relative;
}


.remove-button {
  background-color: red;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}
.cart-image img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.cart-text a h4,
.cart-price h4 {
  font-size: 17px;
  font-weight: bold;
}
.cart-text p {
  margin-top: 10px;
  font-size: 15px;
  color: #7a7a7a;
}
.cart-qty select {
  width: 100%;
  font-size: 15px;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}
.cart-qty h6,
.cart-price h6 {
  margin-bottom: 15px;
  color: #8c8c8c;
  font-size: 13px;
  text-transform: uppercase;
}
.total {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: end;
  padding: 0 30px;
}
.total .sub {
  color: #8c8c8c;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 20px;
}
.total .total-price {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.cart-buttons {
  margin: 90px 0;
}
.cart-buttons a button,
.cart-buttons div button {
  width: 80%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #1cb803;
  color: #ffffff;
}
.cart-buttons a:nth-child(1) button {
  background-color: #000000;
}
/* FOOTER */
.footer {
  background-color: #ececec;
}
.card-name {
  width: 4%;
  margin: 10px;
}
.card-name img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

/* Payment */
.payment-container {
  margin-top: 20px;
}
.radio-container {
  padding: 10px 0;
  text-align: start;
}
.radio-container input {
  font-size: 17px;
}
.radio-container label {
  padding: 0 20px;
  font-size: 17px;
}
.order-detail p {
  margin-top: 2px;
}
.order-detail {
  padding: 30px 0;
  /* background-color: #e5fee9; //lighgreen */
  background-color: #FFC70B;
  
}
.order-detail h5 {
  margin-bottom: 6px;
}
.order-box {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.order-product {
  margin: 40px 0;
  border-bottom: 2px solid #f3f3f3;
  position: relative;
}
.order-product h4 {
  font-size: 13px;
  margin-bottom: 10px;
}
.order-product img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
.table-bordered {
  background-color: #f3f3f3;
}
.subtotal-order button {
  width: 100%;
  padding: 15px 0;
  border: 0px;
  text-transform: uppercase;
  font-size: 13px;
  background-color: #FFC70B;
  color: #ffffff;
}

/* Pagination */
.pagination .page-link {
  padding: 10px 30px;
  color: black;
}
.page-item.active .page-link {
  background-color: black;
  border: 1px solid black;
}

.btn {
  background-color: transparent !important;
  border: 1px solid lightgray !important;
}
.mobile-block {
  display: none;
}

.btn-mobile {
  display: none;
  border: 1px solid white;
}

@media (max-width: 1200px) {
  /* tablet devices */
  .main-wrap {
    margin-left: 220px;
  }

  .navbar-aside {
    max-width: 220px;
  }
}
@media (max-width: 992px) {
  /* small tablet devices */
  .itemlist .col-price {
    text-align: right;
  }
  .itemlist .col-check {
    display: none;
  }

  .card-header .col-check {
    display: none;
  }
}
@media all and (max-width: 768px) {
  /* mobile devices */
  .mobile-block {
    display: block !important;
  }

  .mobile-hide {
    display: none !important;
  }

  .btn-mobile {
    display: inline-block !important;
  }

  .navbar-aside {
    z-index: 900;
    transform: translateX(-100%);
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }
  .navbar-aside .aside-top .logo {
    height: 40px;
  }

  .navbar-aside.show {
    visibility: visible;
    transform: translateX(0);
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  .mobile-offcanvas.show {
    visibility: visible;
    transform: translateX(0);
  }

  .main-header {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .main-header .col-nav {
    order: 1;
    width: 100%;
    /* margin-bottom: 0.8rem; */
    margin-left: auto;
  }
  .main-header .col-search {
    /* flex-grow: 1;
    width: 100%;
    order: 2; */
    display: none;
  }

  .main-wrap {
    margin-left: 0 !important;
  }

  .content-header {
    /* flex-direction: column; */
    align-items: flex-end;
    margin-bottom: 2rem;
  }
  .content-header .content-title {
    margin-bottom: 0rem;
  }

  .card-header input.form-control {
    margin-bottom: 1rem;
  }

  .order-info-wrap .icontext {
    margin-bottom: 1rem;
  }

  .table-responsive table {
    min-width: 700px;
  }
}

@media (max-width: 991.98px) {
  .display-none {
    display: none !important;
  }
  .search-button {
    font-size: 10px;
  }
  .Login-Register a {
    font-size: 10px;
  }
  .btn-group button {
    font-size: 13px;
  }
  .badge {
    top: -10px;
    padding: 5px;
    font-size: 7px;
  }
  .input-group {
    height: 30px;
  }
  .single-image,
  .single-image img {
    height: 400px;
  }
  .author-card-avatar img {
    margin-top: -20%;
  }
  .order-box {
    width: 50px;
    height: 50px;
    font-size: 15px;
  }
  .order-detail p {
    font-size: 12px;
  }
  .order-detail {
    padding: 20px 0;
  }
  .order-detail h5 {
    font-size: 13px;
  }
  .subtotal-order button {
    margin-bottom: 30px;
  }
  .single-product {
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  .pc-header {
    display: none !important;
  }
  .mobile-header {
    display: block;
  }
  .author-card-profile {
    /* border: 1px solid red; */
  }
  .author-card-cover {
    height: 100px;
  }

  .author-card-details {
    font-size: 0.8rem;
  }
  .cart-mobile-icon i {
    font-size: 13px;
    margin-left: 10px;
  }
  .btn-group button {
    font-size: 12px;
  }
  .mobile-header img {
    width: 60px;
  }
  .input-group {
    width: 100%;
    margin-top: 5px;
  }
  .search-button {
    width: 30%;
  }
  .subscribe-head .form-section input[type="email"] {
    height: 45px;
    width: 70%;
  }
  .subscribe-head .form-section input[type="submit"] {
    height: 47px;
    padding: 0 58px;
  }
  .subscribe-head h2 {
    font-size: 25px;
  }
  .contact-Box {
    margin: 30px 0;
  }
  .contactInfo {
    padding: 20px 0;
  }
  .cart-buttons {
    margin: 40px 0;
  }
  .cart-buttons a button,
  .cart-buttons .d-flex button {
    width: 100%;
  }
  .total .sub,
  .total .total-price {
    font-size: 16px;
  }

  .single-image {
    margin-bottom: 50px;
    margin-right: 0;
  }
  .product-name {
    font-size: 23px;
  }
  .author-card-avatar {
    /* display: none; */
    padding: 10px 10px;
  }
  .order-detail {
    margin-top: 20px;
  }
}
@media (max-width: 575.98px) {
  .subscribe-head .form-section input[type="email"] {
    width: 100%;
  }
  .subscribe-head .form-section input[type="submit"] {
    width: 100%;
    padding: 0 0;
    margin-top: 20px;
    margin-left: 0px;
  }
  .subscribe-head h2 {
    font-size: 25px;
  }
  .subscribe-section {
    padding: 70px 0 70px;
  }
  .card-name {
    width: 8%;
    margin: 0 7px;
  }
  .login-center {
    padding: 20px 0;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .center h5 {
    font-size: 17px;
    margin-top: 10px;
  }
  .center p {
    font-size: 17px;
  }
}
@media (max-width: 400px) {
  .subscribe-head h2 {
    font-size: 20px;
  }

  .single-image,
  .single-image img {
    height: 200px;
  }
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 2px #FFC70B dashed;
  width: 60%;
  min-width: 250px;
  max-width: 350px;
  margin: 15px auto;
}

.dropzone-content {
  font-size: 0.8rem;
}

.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}

.btn:hover {
  background-color: blue;
  color: aliceblue;
}

.grid-photos {
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  /* padding: 0 4px; */

}

.file-list {
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
}

.file-list img {
  max-height: 100px;
  width: 100px;
  padding: 0 0 5px 5px;
  object-fit: cover;
  border-radius: 15px;
}

.filevideo {
  height: 150px;
  padding: 0 0 5px 5px;
  object-fit: cover;
}

:root {
  --border-width: 7px;
  --yellow: #FFC70B;
	--red: red;
	--blue: #00dbf9;
	--violet: #da00f7;
}



.sec-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.sec-loading .one {
  height: 100px;
  width: 100px;
  border: 7px solid red;
  border: var(--border-width) solid var(--red);
  transform: rotate(45deg);
  border-radius: 50% 50% 0% 50%;
  position: relative;
  animation: move 0.5s linear infinite alternate-reverse;
  margin-bottom: 2vw;
}
.sec-loading .one::before {
  content: "";
  position: absolute;
  height: 55%;
  width: 55%;
  border-radius: 50%;
  border: 7px solid transparent;
  border: var(--border-width) solid transparent;
  border-top-color: red;
  border-top-color: var(--red);
  border-bottom-color: red;
  border-bottom-color: var(--red);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes move {
  to {
    transform: translateY(15px) rotate(45deg);
  }
}


div.container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.yellow {
	background-color: #FFC70B;
	background-color: var(--yellow);
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
  margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

.red {
	background-color: red;
	background-color: var(--red);
	animation-delay: 0.1s;
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
  margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

.blue {
	background-color: #00dbf9;
	background-color: var(--blue);
	animation-delay: 0.2s;
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
  margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

.violet {
	background-color: #da00f7;
	background-color: var(--violet);
	animation-delay: 0.3s;
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
	margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

@keyframes bounce {
	0%, 50%, 100% {
		transform: scale(1);
		filter: blur(0px);
	}
	25% {
		transform: scale(0.6);
		filter: blur(0px);
	}
	75% {
		filter: blur(0px);
		transform: scale(1.4);
	}
}
.stamped1 {
  width: 90%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.icon-loyal-fill {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.stamped5U {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped5B {
  width: 45%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.stamped7U {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped7B {
  width: 60%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.stamped4 {
  width: 85%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped3 {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped75 {
  width: 75%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped60 {
  width: 60%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.stamped92 {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.stamped12 {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.stamped14U {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.stamped14B {
  width: 60%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stamped15 {
  width: 85%;
   margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.stamped16 {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

.stamped20 {
  width: 85%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 00px;
  margin-bottom: 20px;
}

.icon-loyal-fill-3 {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #0c6157; */
  border-radius: 50%;
  /* color: white; */
}

.icon-loyal-fill-sm {
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #0c6157; */
  border-radius: 50%;
  /* color: white; */
}

.icon-loyal-fill img {
  /* border: 1px dashed red; */
  width: 35px;
  height: 100%;
  /* color: #0c6157; */
  filter: black(100%);
  margin: auto;
}

.icon-loyal-3 {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #0c6157; */
  border-radius: 50%;
}

.icon-loyal-3-sm {
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-loyal-unStamp {
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}



.icon-loyal img {
  width: 40px;
  height: 40px;
  /* border: 1px dashed red; */
  /* filter: invert(95%) sepia(13%) saturate(378%) hue-rotate(199deg)
    brightness(117%) contrast(76%); */
  margin: 0 auto;
}

/* 8 canh */

.bonus{
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 50%;
}

.bonus_sm{
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 50%;
}



.section____center1 {
  width: 1200px;
  margin: 0 auto;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 450px;
  border: 5px solid #fff;
}

.loyalty-container {
  width: 1200px;
  margin: 0 auto;
  /* border: 1px dotted #0c6157; */
}

.card-design {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* /* border: 1px dotted #0c6157; */
}

.card-template {
  border: 1px dashed #0c6157;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.card-template h4 {
  text-shadow: 3px 3px 3px #e3d000;
  margin: 10px 10px 20px 20px;
  font-size: 24px;
  color: #0c6157;
}

.card-outside {
  border: 1px solid gray;
  max-width: 350px;
  /* height: 1000px; */
  border-radius: 10px;
  margin: 0 auto;
}
.card-border {
  border: 1px solid #0c6157;
  /* box-shadow: 3px 3px 3px #e3d000; */
  /* background-color: #0c6157; */
  border-radius: 10px;
  width: 350px;
  height: 550px;
}

.upper-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  /* border: 1px dotted white; */
}

.stamps-required {
  margin-right: 20px;
}

.stamps-required p {
  color: white;
}

.stamps-required h3 {
  color: white;
  float: right;
}

.upper-section img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin: 15px;
}

.upper-section h5 {
  color: white;
  font-size: 16px;
  margin-left: 10px;
}

.stamped-section {
  /* background-color: #e3d000; */
  /* background: linear-gradient(
      #000000,
      rgba(255, 255, 255, 0.2)
    ); */


  background-size: cover;
  height: 200px;
  display: flex;
  flex-direction: column;
}

.bottom-section {
  /* border: 1px solid red; */
  margin-top: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.bottom-section p {
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.bottom-section img {
  margin-top: 0px;
  height: 120px;
}

/* Card information section */
.card-info {
  width: 600px;
  height: 1000px;
  /* border: 1px dashed #0c6157; */
  border-radius: 20px;
  margin-top: 30px;
}

.card-info h3 {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 10px;
  background-color: #e3d000;

  border-bottom: 1px solid #0c6157;
  box-shadow: 0 0 3px #0c6157;
}

.card-info h3:hover {
  background-color: #0c6157;
  color: white;
}

.background-button {
  display: flex;
  width: 500px;
  height: 100px;
  border-bottom: 1px dotted #0c6157;
  justify-content: space-between;
  margin: auto;
  padding: 10px;
}

.value {
  border-left: 12px solid #000;
  padding-left: 10px;
  margin-top: 00px;
}

/* Detail section */

.detail-section {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 20px;
  /* border: 1px solid #000; */
  width: 80%;
}

.decription-section {
  padding: 10px;
  border-bottom: 1px dashed lightgrey;
  max-width: 350px;
  margin: 0 auto;
}

.decription-section p {
  font-size: 12px;
}

.decription-section-h4 {

  font-size: 1.5rem;
  font-weight: 700;
}

/* section data */
.section__data {
  display: grid;
  justify-items: center;
  border: 1px dashed #0c6157;
  background: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.2)
    );
    /* url(../../../assets/logo/pexels-photo-7429579.jpeg); */
  border-radius: 20px;
  padding: 30px;
  margin-top: 20px;
}

.section__data span {
  font-size: 20px;
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
}

.filter-btn1 {
  /* background: #0c6157; */
  /* border-color: lightgray; */
  font-size: 20px;
  text-transform: capitalize;
  margin: 10 0rem;
  letter-spacing: 0px;
  padding: 0rem 0.5rem;
  /* color: white; */
  cursor: pointer;
  font-weight: 500;
  transition: var(--transition);
  border-radius: 20px;
  width: 100px;
}
.filter-btn1:hover {
  background: #e3d000;
  color: var(--clr-white);
}

.data__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px dotted blue; */
  width: 300px;
}

@media screen and (min-width: 200px) {
  .card-design {
    width: 390px;
    display: grid;
    grid-template-columns: 1fr;
    /* border: 1px dotted red; */
  }
}

@media screen and (min-width: 1000px) {
  .card-design {
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.app-header{
  display: flex;
  justify-content: center;
  height: 150px;
  align-items: center;
}
.app-header img{
  height: 50px;
}
.app-header > .app-name{
  font-size: 30px;
  margin: 0 20px;
}

.chat-system{
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-box{
  width: 350px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.username-container{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}
.username-label{
  padding: 0;
  margin: 10px 0;
}
.input{
  border-radius: 5px;
  border: 1px solid #eee;
  outline: none;
  width: 200px;
  height: 25px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px;
  color: #777;
  background: #eee;
}


.online-users-header{
  width: 100%;
  height: 30px;
  background: #028aff;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.users-list{
  list-style: none;
  padding: 0px;
  margin: 0;
  max-height: 300px;
}

.users-list li{
  padding: 5px 10px;
  background: #eee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
}

.users-list li .joined-date{
  font-size: 10px;
  display: inline-block;
  margin: 0 10px;
}

.users-list li:hover{
  background: rgb(177, 176, 176);
}

.message-area1{
  height: 350px;
  overflow-y: auto;
}

.message-control{
  display: flex;
}
.message-control textarea{
  width: 80%;
  border: none;
  background: #eee;
  resize: none;
  outline: none;
  font-family: 'KoHo', sans-serif;
  box-sizing: border-box;
  padding: 5px;
  display: inline-block;
}
.message-control button{
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  outline: none;
  border: none;
  font-family: 'KoHo', sans-serif;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  background: #028aff;
  color: #fff;
}

.new-message-count{
  font-size: 10px;
  display: inline-block;
  background: #028aff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}

.message-area ul{
  padding: 0;
  margin: 0;
  height: 250px;
  overflow-y: scroll;
}
.message-area ul li{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 10px;
}
.message-area ul .user-pic{
  width: 30px;
  height: 30px;
  background: #eee;
  border-radius: 50%;
  overflow: hidden;
}

.message-area ul .user-pic img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.message-area ul .message-text{
  background: #028aff;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  padding: 2px 5px;
  box-sizing: border-box;
  margin: 0 5px;
  max-width: 270px;
}

.file-input-container{
  position: relative;
}
.file-input-container input[type="file"]{
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.file-input-container label{
  display: block;
  color: #028aff;
  padding: 0 10px;
  cursor: pointer;
  background: #eee;
  height: 100%;
  display: flex;
  align-items: center;
}

.image-container{
  background: #eee;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 0 5px;
}

.attachement-display{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 10px;
  background: #eee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.attachment-name{
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  margin: 0 5px;
}
.remove-attachment{
  font-size: 20px;
  font-weight: 500;
  margin: 0 5px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}

.sendButton {
  cursor: pointer;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #4e01a3;
  padding: 5px;
  display: inline-block;
  border-width: 4px;
  border-color: mediumslateblue;
  width: 15%;
}

.sendButton:hover {
  background: #5e2899;
}

.uploadButton:hover {
  background: #5e2899;
}

.uploadButton {
  cursor: pointer;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #4e01a3;
  padding: 15px 14px 14px 14px;
  display: inline-block;
  border: 5px outset mediumslateblue;
}

.uploadImage {
  width: 40px;
}
.dotsContainer {
  width: 160px;
  height: 25px;
  background: #f2f2f2;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#dot1,
#dot2,
#dot3 {
  width: 7px;
  height: 7px;
  background: #cacaca;
  border-radius: 50%;
  margin: 3px;
  transition: all 0.5s ease-in-out;
  animation: typing 1s infinite;
}

#dot1 {
  animation-delay: 1s;
}

#dot2 {
  animation-delay: 0.5s;
}

#dot3 {
  animation-delay: 0.8s;
}

@keyframes typing {
  0% {
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
  }

  50% {
    transform: translateY(-5px);
    transition: all 0.5s ease-in-out;
  }

  100% {
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
  }
}

message-item {
  display: flex;
  margin: 8px auto;
}

.message-avatar-container {
  align-self: flex-end;
  margin: 0px 8px;
}

.message-avatar {
  vertical-align: middle;
  border-radius: 50%;

  height: 28px;
  width: 28px;
}

