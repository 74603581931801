:root {
  --border-width: 7px;
  --yellow: #FFC70B;
	--red: red;
	--blue: #00dbf9;
	--violet: #da00f7;
}



.sec-loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.sec-loading .one {
  height: 100px;
  width: 100px;
  border: var(--border-width) solid var(--red);
  transform: rotate(45deg);
  border-radius: 50% 50% 0% 50%;
  position: relative;
  animation: move 0.5s linear infinite alternate-reverse;
  margin-bottom: 2vw;
}
.sec-loading .one::before {
  content: "";
  position: absolute;
  height: 55%;
  width: 55%;
  border-radius: 50%;
  border: var(--border-width) solid transparent;
  border-top-color: var(--red);
  border-bottom-color: var(--red);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes move {
  to {
    transform: translateY(15px) rotate(45deg);
  }
}


div.container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.yellow {
	background-color: var(--yellow);
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
  margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

.red {
	background-color: var(--red);
	animation-delay: 0.1s;
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
  margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

.blue {
	background-color: var(--blue);
	animation-delay: 0.2s;
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
  margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

.violet {
	background-color: var(--violet);
	animation-delay: 0.3s;
  width: 1vw;
	height: 1vw;
	border-radius: 100%;
	margin: 0.5vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	animation: bounce 1.5s 0.5s linear infinite;
}

@keyframes bounce {
	0%, 50%, 100% {
		transform: scale(1);
		filter: blur(0px);
	}
	25% {
		transform: scale(0.6);
		filter: blur(0px);
	}
	75% {
		filter: blur(0px);
		transform: scale(1.4);
	}
}