.stamped1 {
  width: 90%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.icon-loyal-fill {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.stamped5U {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped5B {
  width: 45%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.stamped7U {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped7B {
  width: 60%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.stamped4 {
  width: 85%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped3 {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped75 {
  width: 75%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.stamped60 {
  width: 60%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.stamped92 {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.stamped12 {
  width: 70%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.stamped14U {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.stamped14B {
  width: 60%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stamped15 {
  width: 85%;
   margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.stamped16 {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;
}

.stamped20 {
  width: 85%;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 00px;
  margin-bottom: 20px;
}

.icon-loyal-fill-3 {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #0c6157; */
  border-radius: 50%;
  /* color: white; */
}

.icon-loyal-fill-sm {
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #0c6157; */
  border-radius: 50%;
  /* color: white; */
}

.icon-loyal-fill img {
  /* border: 1px dashed red; */
  width: 35px;
  height: 100%;
  /* color: #0c6157; */
  filter: black(100%);
  margin: auto;
}

.icon-loyal-3 {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #0c6157; */
  border-radius: 50%;
}

.icon-loyal-3-sm {
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-loyal-unStamp {
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}



.icon-loyal img {
  width: 40px;
  height: 40px;
  /* border: 1px dashed red; */
  /* filter: invert(95%) sepia(13%) saturate(378%) hue-rotate(199deg)
    brightness(117%) contrast(76%); */
  margin: 0 auto;
}

/* 8 canh */

.bonus{
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 50%;
}

.bonus_sm{
  display: flex;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 50%;
}


